import {FC} from 'react'
import {Link} from 'react-router-dom'
import Card from 'react-bootstrap/Card';
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

function Service1() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Birth Certificate</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Immigration Service</Card.Subtitle>
        <Card.Text>
          Request for a Birth certificate for new born or a replacement for adults
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service2() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>National ID Card</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Immigration Service</Card.Subtitle>
        <Card.Text>
          Request for a new or replace your existing National Identification Card
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service3() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Returns Filing</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Tax Service</Card.Subtitle>
        <Card.Text>
          Filing returns for the past year to the tax authority for adults with a PIN
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service4() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Passport Application</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Immigration Service</Card.Subtitle>
        <Card.Text>
          Apply for a passport or replace an existing one
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service5() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Land Search</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Land Services</Card.Subtitle>
        <Card.Text>
          Search the land database for a specific land details
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service6() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Driving License</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Transport Services</Card.Subtitle>
        <Card.Text>
          Apply or renew driver's license
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service7() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>PSV License</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Transport Services</Card.Subtitle>
        <Card.Text>
          Apply or renew PSV license
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service8() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Tax PIN Application</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Tax Services</Card.Subtitle>
        <Card.Text>
          Apply for a Tax PIN
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service9() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Business Permit</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Business Services</Card.Subtitle>
        <Card.Text>
          Apply or renew your business permit
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service10() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Business Name Registration</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Business Services</Card.Subtitle>
        <Card.Text>
          Search and register a new business name
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service11() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Tax Compliance Application</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Tax Services</Card.Subtitle>
        <Card.Text>
          Apply for a Tax Compliance Certificate (TCC) for your business
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

function Service12() {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Marriage Certificate Application</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Marriage Services</Card.Subtitle>
        <Card.Text>
          Apply for a Marriage certificate from the office of the AG
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link href="/auth">Request Service</Card.Link>
      </Card.Body>
    </Card>
  );
}

const HomePage: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
      {/* begin::Heading */}
      <div className='d-flex flex-row flex-grow flex-center mb-10'>
            <img alt='Slider' src={toAbsoluteUrl('/media/misc/government_services_sl_sm.jpeg')} className='h-400px' />
      </div>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Welcome to Sierra Leone E-Service Portal</h1>

        <div className='fw-bold fs-4'>
           Citizens and Foreign Residents can now apply for Government services and pay via debit Cards.
        </div>
        <div className='text-gray-400 fw-bold fs-4'>Select a service to get started:</div>
      </div>
      {/* begin::Heading */}
      <div className='d-flex  flex-row justify-content-between align-items-center column-gap-10 p-10'>
        <Service1 />
        <Service2 />
        <Service3 />
      </div>
      <div className='d-flex flex-row flex-center align-items-center column-gap-10 p-10'>
        <Service4 />
        <Service5 />
        <Service6 />
      </div>
      <div className='d-flex flex-row flex-center align-items-center column-gap-10 p-10'>
        <Service7 />
        <Service8 />
        <Service9 />
      </div>
      <div className='d-flex flex-row flex-center align-items-center column-gap-10 p-10'>
        <Service10 />
        <Service11 />
        <Service12 />
      </div>
      
        {/* begin::Link */}
        <Link to='/auth' className='btn btn-primary'>
          Login or Register
        </Link>
        {/* end::Link */}

        <p className='mt-20'>&copy; 2022. All Rights Reserved. Government of Sierra Leone</p>
      </div>
    </div>
  )
}

export {HomePage}
