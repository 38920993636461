/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
 
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Services</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/services/pages'
        title='Immigration'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      > 
          <AsideMenuItem to='/services/pages/immigration/birth_certificates' title='Birth Certificates' hasBullet={true} />
          <AsideMenuItem to='/services/pages/immigration/national_id_cards' title='National ID Card' hasBullet={true} />
          <AsideMenuItem to='/services/pages/immigration/passports' title='Passports' hasBullet={true} />
          <AsideMenuItem
            to='/services/pages/immigration/resident_visas'
            title='Resident VISAs'
            hasBullet={true}
          />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/services/transport'
        title='Transport'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/services/account/overview' title='Driving Licenses' hasBullet={true} />
        <AsideMenuItem to='/services/account/settings' title='Inspection Certificates' hasBullet={true} />
        <AsideMenuItem to='/services/account/settings' title='Car Log Books' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Tax Services'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/services/pin_application' title='PIN Application' hasBullet={true} />
        <AsideMenuItem to='/services/returns_filing' title='Returns Filing' hasBullet={true} />
        <AsideMenuItem to='/services/tcc' title='Tax Compliance Certificate' hasBullet={true} />
       
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Business Services'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/services/business_permits' title='Business Permits' hasBullet={true} />
        <AsideMenuItem to='/services/business_name_registration' title='Business Name Registration' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Lands Services'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/services/land_search' title='Land Search' hasBullet={true} />
        <AsideMenuItem to='/services/land_rent' title='Land Rent' hasBullet={true} />
      </AsideMenuItemWithSub>
    </>
  )
}
